@import '../../theme/styles/base/ovante_variables.scss';

.leaderboard-profile__container{
  width: 76px;
  height: 179.5px;
  background: linear-gradient(180deg, #FDC200 44.57%, rgba(255, 255, 255, 0) 100%);
  position: relative;
}
.leaderboard-profile-img__container{
  position: absolute;
  top: -38px;
}
.ion-avatar-profile-img{
  width: 76px;
  height: 84px;
  position: absolute;
  top: -38px;
}
.leaderboard-profile-img{
  // border-radius: 38px !important;
  // overflow: hidden;
  width: 76px;
  height: 84px;
}
.mask{
  -webkit-mask-image: url('../../assets/images/leaderboard-o-device.svg');
  mask-image: url('../../assets/images/leaderboard-o-device.svg');
  mask-repeat: no-repeat;
  mask-size: 100%;
}
.leaderboard-number{
  font-size: 0.75rem;
  line-height: 1.05rem;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #FDC200;
  position: absolute;
  top: 0px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
