@import "../../theme/styles/base/ovante_variables.scss";


.bottom-nav{
    --background: transparent;
    --border: 0;
    --color: #979797;
    --color-selected: #FE6800;
    //position: relative;
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 73px;
    @supports (-webkit-touch-callout: none) {
        bottom: -30px;
    }
    &::before{
        content: ' ';
        display: block;
        position: absolute;
        background-position: top;
        width: 100%;
        height: 67px;
        background: url("../../assets/images/bottom-nav-bg.svg") no-repeat;
        background-size: 100%;
        top: 10%;
        @media screen and (min-width: 560px) {
            background: url("../../assets/images/bottom-nav-bg-desktop.svg") no-repeat;
            height: 72px;
            background-size: cover;
        }
    }
    
    // &.rewards, &.tools, &.experts{
    //     --background: #ffffff;
    // }
    // &.stories{
    //     --background: #165F74;
    // }
}

.ion-tab__btn{
    --padding-end: 0;
    --padding-start: 0;
    --padding-top: 35px;
    background: transparent;
    position: relative;
    &::before{
        content: ' ';
        display: block;
        position: absolute;
        width: 66px;
        height: 66px;
        background: transparent;
        left: 50%;
        transform: translateX(-50%);
        bottom: 10px;
    }
    @media screen and (min-width: 560px) {
        max-width: 90px;
    }
    .unbounded{
        top: -35px
    }
    &.experts{
        &::before{
            background: url("../../assets/images/ic_experts.svg") no-repeat;
            @media screen and (min-width: 560px) {
                bottom: 15px;
            }
        }
    }
    &.tools{
        &::before{
            background: url("../../assets/images/ic_tools.svg") no-repeat;
            @media screen and (min-width: 560px) {
                bottom: 15px;
            }
        }
    }
    &.home{
        &::before{
            background: url("../../assets/images/ic_home.svg") no-repeat;
            bottom: 15px;
        }
    }
    &.stories{
        &::before{
            background: url("../../assets/images/ic_stories.svg") no-repeat;
            @media screen and (min-width: 560px) {
                bottom: 15px;
            }
        }
    }
    &.rewards{
        &::before{
            background: url("../../assets/images/ic_rewards.svg") no-repeat;
            @media screen and (min-width: 560px) {
                bottom: 15px;
            }
        }
    }
    &.tab-selected{
        font-family: 'Ubuntu Medium';
        &.experts{
            &::before{
                background: url("../../assets/images/ic_experts_selected.svg") no-repeat;
            }
        }
        &.tools{
            &::before{
                background: url("../../assets/images/ic_tools_selected.svg") no-repeat;
            }
        }
        &.home{
            &::before{
                background: url("../../assets/images/ic_home_selected.svg") no-repeat;
            }
        }
        &.stories{
            &::before{
                background: url("../../assets/images/ic_stories_selected.svg") no-repeat;
            }
        }
        &.rewards{
            &::before{
                background: url("../../assets/images/ic_rewards_selected.svg") no-repeat;
            }
        }
    }
}

.ion-btn__label{
    font-size: 0.625rem;
    line-height: 0.875rem;
    margin: 0;
}

.ion-tab__notification{
    background: $vOrange;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: -32px;
    right: 20px;
}