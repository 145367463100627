@import '../../../theme/styles/base/ovante_variables.scss';

.page-content__profile {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  .profile-icon {
    width: 80px;
    height: 80px;
  }
}

.ion-list {
  background: transparent;
  .ion-radio-group {
    .item-label {
      --background: transparent;
      --inner-border-width: 0;
      background: $white;
      box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
      border-radius: 8px;
      margin-bottom: 10px;
      &.item-radio-checked {
        border: 2px solid $vOrange;
      }
      ion-radio{
        width: 20px;
        height: 20px;
        --border-width: 0px;
        &.radio-checked {
          --color-checked: #FE6800;
          --color: #ffffff;
          background: $vOrange;
          position: relative;
          visibility: hidden;
          &::before {
            content: " ";
            display: block;
            position: absolute;
            background: url("../../../assets/images/icons/radio-checked.svg") no-repeat;
            width: 20px;
            height: 20px;
            right: 0px;
            bottom: 0px;
            visibility: visible;
          }
        }
      }
      ion-label {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.125rem;
        color: $vLightBlack;
      }
    }
  }
}

.input-textarea{
  width: 100%;
  padding: 8px 15px;
  padding-right: 35px;
  background: $white;
  color: $vLightBlack;
  font-size: 1.125rem;
  line-height: 1.8rem;
  border: 2px solid #b6b2af;
  border-radius: 12px;
  &:empty:before {
    content: attr(data-placeholder);
    color: #928b87;
    opacity: 0.5;
  }
}
