@import '../../../theme/styles/base/ovante_variables.scss';

.page-tools{
  --ion-background-color: #ffffff;
  &.page-tools-orange{
      --ion-background-color: #FDF6F1;
  }
  .register-header {
    //background-color: $vDeepSkyBlue;
    background-color: transparent;
    background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
    margin-bottom: -20px;
  }
  // .page-content{
  //   --padding-bottom: 110px;
  // }
}
.page-content_unlock-tools{
  padding: 20px;
  background: $vLightOrnage;
}
.page-content_lock-tools{
  padding: 20px 0;
}
.unlock-tools__title{
  font-size: 1.125rem;
  line-height: 1.575rem;
}
.unlock-tools__desc{
  font-size: 0.875rem;
  line-height: 1.138rem;
}
.lock-tools__title{
  font-size: 0.875rem;
  line-height: 1rem;
}
.lock-tools__desc{
  font-size: 0.75rem;
  line-height: 0.975rem;
}
.lock-tools{
  .other-user__text {
    padding-left: 0px;
    margin-left: 0 !important;
  }
}
.lock-tools{
  .lock-tools-card {
    //display: flex;
      &:nth-child(odd){
          padding-right: 5px;   
      }
      &:nth-child(even){
          padding-left: 5px;   
      }
    .tool-card__container{
      padding-right: 10px;
    }
  }
}
.tools-popup{
  .ion-popup__success-icon{
    width: 42.5px;
    height: 42.5px;
  }
  .tool-popup-btn{
    width: 71px;
    height: 44px;
    margin: 0 auto;
  }
  .tools-popup__title{
    font-size: 1.375rem;
    line-height: 1.925rem;
  }
  .tools-popup__detail-text, .tools-popup__get-online{
    font-size: 0.875rem;
    line-height: 1.225rem;
  }
}