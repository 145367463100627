@import "../../theme/styles/base/ovante_variables.scss";

.page-district {
  .page-content {
    --padding-bottom: 0px;
    --padding-top: 0px;
  }
  .page-content__bg {
    background: $black;
    height: 40px;
  }
  .ion-item {
    width: 100%;
    --min-height: 50px;
    .ion-input {
      --padding-start: 15px !important;
    }
  }
  .page-content__container {
    margin-top: -30px;
    background: $vLightOrnage;
    border-radius: 10px 10px 0 0;
    &.stretch-container {
      padding-bottom: 0px;
    }
    .page-content__businessheader {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      .page-content__title {
        margin: 0;
        margin-left: 10px;
      }
      .ion-button__back-btn {
        --background: transparent;
        --border: 0;
        --box-shadow: none;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        height: 25px;
        .ion-button__back-icon {
          width: 9px;
          height: 18px;
        }
      }
    }
    .page-content__container-inner {
      display: flex;
      flex-direction: column;
      // height: -webkit-fill-available;
      // justify-content: space-between;
      .page-content__whitebox {
        flex-direction: column;
        padding: 0 18px;
        margin-bottom: 10px !important;
        align-items: baseline;
        &.businees-cell {
          padding: 0 5px;
          background: transparent;
          margin: 10px 0 30px;
          .ion-button__btnoutline {
            width: 100%;
          }
        }
        &.describe-business {
          padding: 18px;
          background: $white;
          margin: 0px 0px 20px;
          .page-content__title {
            color: #7b7470;
          }
        }
      }
      .stretch-container {
        background: transparent;
        padding: 20px 25px;
      }
    }
  }
}
