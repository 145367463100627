@import "../../theme/styles/base/ovante_variables.scss";

.page-to-do{
    --ion-background-color: #ffffff;
    &.page-to-do-orange{
        --ion-background-color: #FDF6F1;
        &.page-to-do-white{
            --ion-background-color: #ffffff;
        }
    }
    .register-header {
        //background-color: #73CBE5;
        background-color: transparent;
        background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
        margin-bottom: -20px;
    }
    .page-content {
        --padding-bottom: 0;
      }
    .ion-item {
        background: $vGreen;
        border-radius: 100%;
        width: 18px;
        height: 18px;
        --min-height: 18px;
        text-align: center;
        --inner-padding-end: 0px;
    }
    .ion-item__input {
    --background: transparent;
    --color: #FFFFFF;
    font-size: 0.656rem;
    line-height: 0.75rem;
    border: 0;
    border-radius: 0px;
    width: 18px;
    height: 18px;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --padding-top: 0px;
    --padding-bottom: 0px;
    }
    .checkbox{
        width: 20px;
        height: 20px;
        margin: 0;
        margin-right: 10px;
        --border-radius: 5px !important;
        --border-color: #B6B2AF;
        --background: #ffffff;
        --border-width: 2px;
        display: table;
        z-index: 0;
        &.checkbox-checked{
            --background-checked: #165F74;
            --checkmark-color: #ffffff;
            --checkmark-width: 3px;
            --border-color-checked: #165F74;
            //--border-width: 2px;
        }
    }
}
.page-content__ovante-task-container{
    background: $vLightOrnage;
    margin-top: -20px;
    padding: 20px;
    padding-top: 35px;
}

.page-content__ovante-task{
    background: rgba($color: $vGreen, $alpha: 0.05);
    border-radius: 8px;
}
.page-content__personal-task, .page-content__ovante-task {
    padding: 20px;
}
// .page-content__personal-task{
//     .page-content__task-list{
//         height: calc(100% - 95px);
//         overflow: scroll;
//         margin-right: -20px;
//     }
// }
.page-content__task{
    display: flex;
    align-items: center;
    .task__title{
        font-size: 0.875rem;
        line-height: 1rem;
    }
    .task__count{
        width: auto;
        text-align: center;
        margin-left: 10px;
    }
}
.ovante-task__list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .task__icon{
        width: 12px;
        height: 16px;
    }
    &:last-child{
        margin-bottom: 0 !important;
    }
}
.personal-task__list{
    display: flex;
    align-items: center;
    &:last-child{
        margin-bottom: 60px !important;
    }
    &.task__checked{
        .task__name{
            text-decoration: line-through;
            color: rgba(56, 50, 46, 0.4);
            text-decoration-color: $black;
        }
    }
}
.task-list__container{
    width: calc(100% - 30px);
}
.task__name{
    font-size: 0.875rem;
    line-height: 1.4rem;
}
.task__date{
    font-size: 0.75rem;
    line-height: 1.2rem;
    margin-top: 5px;
}
.task-undo__btn{
    width: 74px;
    height: 44px;
    display: block;
    position: fixed;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
}
// .page-content__personal-task{
//     height: calc(100% - 152px);
//     overflow: scroll;
// }
.page-content__add-task{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20px;
    padding-bottom: 10px;
    height: auto;
    &::before{
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 66px;
        background: url("../../assets/images/add-task-bg.svg") no-repeat;
        top: -5px;
        left: 0px;
        background-size: 100%;
    }
    .ion-item{
        background: transparent;
        width: 100%;
        height: unset;
        text-align: left;
        --min-height: auto;
        border-radius: unset;
        --padding-start: 2px;
        .add-task__name, .add-task__title{
            font-size: 1rem;
            line-height: 1.6rem;
            height: unset;
            width: 100%;
        }
        .add-task__title{
            --padding-top: 5px;
        }
        .add-task__name{
            --padding-top: 3px;
            width: calc(100% - 30px);
        }
    }
        
}
.add-task{
    display: flex;
    align-items: center;
    &:nth-child(2){
        margin-top: 5px;
    }
    .react-datepicker__close-icon{
        top: 0;
        padding-right: 0;
        right: -25px;
    }
}
.add-task__icon{
    margin-right: 10px;
    width: 24px;
    height: 26px;
    display: block;
    z-index: 1;
}
.add-task__title{
    font-size: 1rem;
    line-height: 1.6rem;
    display: block;
    z-index: 1;
}
.add-task__icon-orange{
    margin-right: 6px;
    width: 20px;
    height: 20px;
    z-index: 1;
}
.add_due-date{
    font-size: 0.75rem;
    line-height: 1.2rem;
    opacity: 0.5;
    z-index: 1;
    background: transparent;
    margin-right: 6px;
}
.add-tick{  
    width: 17px;
    height: 13px;
    z-index: 1;
}
.close-date{
    z-index: 1;
    width: 16px;
    height: 16px;
    background: #165F74;
    text-align: center;
    color: #ffffff;
    border-radius: 50%;
    font-size: 0.563rem;
    line-height: 1rem;
}