@import '../../../theme/styles/base/ovante_variables.scss';

.stepbar-wrapper {
  // background: white;
}

/* Progress Tracker v2 */
ol.progress[data-steps="2"] li {
  width: 50%;
}
ol.progress[data-steps="2"] {
  margin-left: -40%;
  width: 180%;
}
ol.progress[data-steps="3"] li {
  width: 33.33%;
}
ol.progress[data-steps="3"] {
  margin-left: -20%;
  width: 140%;
}
ol.progress[data-steps="4"] li {
  width: 25%;
}
ol.progress[data-steps="4"] {
  margin-left: -12.5%;
  width: 125%;
}
ol.progress[data-steps="5"] li {
  width: 20%;
}
ol.progress[data-steps="5"] {
  margin-left: -10%;
  width: 120%;
}
ol.progress[data-steps="6"] li {
  width: 16.66%;
}
ol.progress[data-steps="6"] {
  margin-left: -8.33%;
  width: 116.66%;
}
ol.progress[data-steps="7"] li {
  width: 14%;
}
ol.progress[data-steps="7"] {
  margin-left: -7%;
  width: 114%;
}
ol.progress[data-steps="8"] li {
  width: 12%;
}
ol.progress[data-steps="8"] {
  margin-left: -4%;
  width: 112%;
}
ol.progress[data-steps="9"] li {
  width: 11%;
}
ol.progress[data-steps="9"] {
  margin-left: -5%;
  width: 111%;
}
ol.progress[data-steps="10"] li {
  width: 9%;
}
ol.progress[data-steps="10"] {
  margin-left: -4%;
  width: 117%;
}
ol.progress[data-steps="11"] li {
  width: 9%;
}
ol.progress[data-steps="11"] {
  margin-left: -4%;
  width: 109%;
}

.progress {
  width: 100%;
  list-style: none;
  list-style-image: none;
  // margin: 20px 0 20px 0;
  margin: 4px 0 27px 0;
  padding: 0 15px;
}

.progress li {
  float: left;
  text-align: center;
  position: relative;
}

.progress .name {
  display: block;
  text-align: center;
  margin-bottom: 1em;
  color: $black;
  opacity: 0.3;
}

.progress .step {
  color: $black;
  border: 3px solid #E2E2E2;
  background-color: #E2E2E2;
  border-radius: 50%;
  line-height: 1.2;
  width: 10px;
  height: 10px;
  display: inline-block;
  z-index: 0;
}

.progress .step span {
  opacity: 0.3;
}

.progress .active .name,
.progress .active .step span {
  opacity: 1;
}



.progress .step:before {
  content: "";
  display: block;
  background-color: #E2E2E2;
  height: 4px;
  width: 50%;
  position: absolute;
  top: 8px;
  //bottom: 0.45em;
  left: 0;
  z-index: -1;
}

.progress .step:after {
  content: "";
  display: block;
  background-color: #E2E2E2;
  height: 4px;
  width: 50%;
  position: absolute;
  top: 8px;
  //bottom: 0.45em;
  right: 0;
  z-index: -1;
}

.progress li:first-of-type .step:before {
  display: none;
}

.progress li:last-of-type .step:after {
  display: none;
}

.progress .done .step,
.progress .done .step:before,
.progress .done .step:after,
.progress .active .step,
.progress .active .step:before {
  background-color: $vOrange;
}

.progress .done .step,
.progress .active .step {
  border: 3px solid $vOrange;
}

.progress .active .step {
  //border: 1px solid $vOrange;
  //box-shadow: inset 0px 0px 0px 1px #fff;
  border: 3px solid #FDDCC7;
  width: 16px;
  height: 16px;
  margin-top: 2px;
}

.stepbar-wrapper {
  background-color: $white;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(47, 138, 165, 0.1);
  position: relative;
  top: -40px;
  z-index: 999;
  margin: 0 20px;
}