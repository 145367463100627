@import '../../theme/styles/base/ovante_variables.scss';

.page-review-program {
    --ion-background-color: #ffffff;
    .capsul-card__nothum {
        margin-bottom: 10px;
    }
}
.topic-program__container{
    padding: 20px 20px 30px !important;
}
.capsul-card__extra-activities {
    .capsul-card__label {
        font-size: 0.875rem;

    }
    p {
        margin: 0;
        color: #232323;
    }
    .icon-text {
        display: flex;
        font-size: 0.625rem;
        color: $vOrange;
        font-family: 'Ubuntu Medium';
        align-items: center;
        margin-top: 3px;
        

        ion-img {
            margin-right: 5px;
        }
    }
}