@import '../../theme/styles/base/ovante_variables.scss';

.page-register{
    &.page-register-white{
        --ion-background-color: #ffffff;
        .register-header{
            margin-bottom: 0px !important;
        }
    }
    .page-content__button{
        position: unset;
        bottom: unset;
        left: unset;
        padding: unset;
        transform: unset;
    }
    // .ion-item__input {
    //     --padding-start: 45px !important;
    // }
}
.page-content-register{
    margin-top: -20px !important;
    @media screen and (min-width: 560px) {
        margin-top: -100px !important;
        padding-top: 80px !important;
        --padding-top: 80px !important;
    }
}
.page-content__whitebox{
    &.web-register-whitebox{
        .whitebox__content{
            padding-left: 0;
        }
    }
}
.page-content_web-botton-container{
    text-align: center;
    padding: 25px !important;
    padding-bottom: 5px !important;
}
.web-external{
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.web-playstore{
    width: 168.75px;
    height: 50px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
}
.web-appstore{
    width: 120px;
    height: 40px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
}
.page-content_web-desc{
    font-size: 1rem;
    line-height: 1.4rem;
}
.page-content__marginTop{
    margin-top: 16px;
}
.header-green {
    background: $vGreen;
}