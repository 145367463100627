@import "../../../theme/styles/base/ovante_variables.scss";

.ion-item {
  --padding-start: 0px;
  --inner-border-width: 0;
  --inner-padding-end: 5px;
  --ripple-color: transparent;
  --background-hover: transparent;
  .checkbox {
    width: 32px;
    height: 32px;
    margin: 0;
    margin-right: 14px;
    --border-radius: 8px !important;
    --border-color: #b6b2af;
    --background: #ffffff;
    --border-width: 2px;
    --size: 5px;
    &.checkbox-checked {
      --background-checked: #2293b5;
      --checkmark-color: #ffffff;
      --checkmark-width: 0px;
      --border-color-checked: #2293b5;
      --border-width: 2px;
      --size: 5px;
      position: relative;
      &::before {
        content: " ";
        background: url(../../../assets/images/icons/checkbox-selected.svg)
          no-repeat;
        position: absolute;
        display: block;
        width: 19px;
        height: 14px;
        z-index: 1;
        top: 9px;
        left: 6px;
      }
    }
  }
  .ion-label {
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.8rem;
    font-weight: 400;
    color: $vLightBlack;
  }
}
