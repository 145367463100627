.page-certificate__container{
    //z-index: 99999;
    padding: 0px 25px 18px;
    // text-align: center;
    // position: absolute;
    // top: 0;
    //left: 0;
    // left: 50%;
    // transform: translateX(-50%);
}
.page-certificate__img{
    border-top-color: #252525;
    border-bottom-color: #252525;
    border-left-color: #8e8e8e;
    border-right-color: #3c3c3b;
    border-style: inset;
    border-width: 10px;
}
.certificate__button-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.certificate__button-go-back{
    margin-right: 10px;
}
.certificate__button-share{
    margin-left: 10px;
}
.certificate__button-go-back, .certificate__button-share{
    width: 130px;
}