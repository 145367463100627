@import '../../theme/styles/base/ovante_variables.scss';

.page-login-with-phone{
    .page-content__button{
        position: unset;
        bottom: unset;
        left: unset;
        padding: unset;
        transform: unset;
    }
    // .ion-item__input {
    //     --padding-start: 45px !important;
    // }
}
    
.page-content__marginTop{
    margin-top: 16px;
}
