@import "../../theme/styles/base/ovante_variables.scss";

.page-splash{
  --ion-background-color: #73CBE5;
}
.page-splash-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.splash-logo{
  width: 110px;
  height: 168px;
}