.capsul-card {
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(47, 138, 165, 0.2);
    --background: #FFFFFF;

    ion-item {
        --background: #FFFFFF;
        --padding-start: 0 !important;
        padding-inline-start: 0;
        --padding-inline-end:0 !important;
        border-style: none;
        --inner-border-width: 0;
    }

    ion-thumbnail {
        width: 77px;
        height: 65px;
        border-radius: 8px;
        overflow: hidden;
    }
}

.capsul-card__label {
    white-space: inherit !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}


.capsul-card__nothum {
    ion-item {
        --padding-start: 20px !important;
        --padding-inline-end:20px !important;
    }

}
.capsul-card__img_right-space {
    margin-right: 12px;
}

.capsul-card__large {
    height: 128px;
    .capsul-card__label {
        color: $vGreen;
        font-family: 'Ubuntu Bold';
    }
}

.capsul-card__time {
    background: $vOrange;
    max-width: fit-content;
    padding:0 15px;
    color: $white;
    border-radius: 6px;
    font-size: 0.75rem;
    height: 20px;
    display: flex;
    align-items: center;
}

.capsul-card__thum-group {
    position: relative;
    .capsul-card__time {
        position: absolute;
        top: 4px;
        left: 3px;
    }

    .icon__completed {
        position: absolute;
        top: 19px;
        left: 25px;
        z-index: 5;
        display: block;
        width: 26px;
        height: 27px;
    }

}

.capsul-card__thum-group_overlay {
    &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.capsul-card__other {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
        font-size: 0.625rem;
        font-family: 'Ubuntu Bold';
        color: $vGray;
        letter-spacing: 0.01em;
        line-height: 140%;
    }
   
}

.completed-card {
    .custom-button {
        text-align: center;
        width: 177px;
        font-size: 0.75rem;
        position: relative;
        top: 8px;
        left: 9px;
        padding: 3px 5px 10px;
    }
    .page__section-title {
        margin-top: 30px;
        margin-bottom: 11px;
    }
}

.capsul-card__mid {
    width: 200px;
}

.capsul-card__top-img {
    background: #C91D56;
    width: 46px;
    height: 35px;
    border-radius: 8px;
    ion-img {
        width: 25px;
        margin-left: 12px;
        padding-top: 7px;
    }
}
.capsul-card__prevent-overflow {
    position: relative;
    .capsul-card__top-img {
        position: absolute;
        top: -17px;
        left: 50%;
        right: 50%;
        margin-left: -23px;
        z-index: 9;
    }
}

.capsul-card__generator {
    padding-top: 25px;
    padding-bottom: 20px;
    max-width: 212px;
    margin-left: auto;
    margin-right: auto;
    ion-item {
        --min-height: auto;
        margin: 0 auto;
        width: max-content;
        display: block;
        margin-bottom: 5px;
    }
    .capsul-card__label {
        margin: 0px;
    }
}

.activity-card__wrapper {
    margin-bottom: 47px;
    .capsul-card {
        margin-bottom: 0 !important;
    }
}

.user_group_img {
    display: flex;
    ion-img::part(image) {
        width: 20px;
        height: 20px;
    }
}

.user_group_overlap_1 {
    position: relative;
    left: -5px;
}

.user_group_overlap_2 {
    position: relative;
    left: -10px;
}

.button-position {
    position: relative;
    .button-position__bottom {
        max-width: 200px;
        width: 100%;
        float: right;
        top: -25px;
        position: relative;
        right: 18px;
    }
}