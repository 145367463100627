@import '../../theme/styles/base/ovante_variables.scss';

.bottom-drawer-container {
  z-index: 100;
  position: absolute;
  bottom: 0;
  width: 100%;
  ion-card {
    --background: #FDF6F1;
    border-radius: 12px !important;
    padding: 20px;
    margin: 0;
    .drawer-back {
      display: flex;
      margin-bottom: 15px;
      color: $vBlue;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.575rem;
      position: relative;
      .back-arrow {
        margin-right: 10px;
      }
    }
  }
  @media screen and (min-width: 560px){
    max-width: 540px;
    left: calc(50% - 270px);
  }
}
