@font-face {
    font-family: 'Ubuntu Regular'; //This is what we are going to call
    src        : url('../../../assets/fonts/Ubuntu-Regular.ttf'),
        url('../../../assets/fonts/Ubuntu-Regular.woff'),
        url('../../../assets/fonts/Ubuntu-Regular.woff2'),
        url('../../../assets/fonts/Ubuntu-Regular.svg'),
        url('../../../assets/fonts/Ubuntu-Regular.eot');
}

@font-face {
    font-family: 'Ubuntu Medium'; //This is what we are going to call
    src        : url('../../../assets/fonts/Ubuntu-Medium.ttf'),
        url('../../../assets/fonts/Ubuntu-Medium.woff'),
        url('../../../assets/fonts/Ubuntu-Medium.woff2'),
        url('../../../assets/fonts/Ubuntu-Medium.svg'),
        url('../../../assets/fonts/Ubuntu-Medium.eot');

}

@font-face {
    font-family: 'Ubuntu Light'; //This is what we are going to call
    src        : url('../../../assets/fonts/Ubuntu-Light.ttf'),
        url('../../../assets/fonts/Ubuntu-Light.woff'),
        url('../../../assets/fonts/Ubuntu-Light.woff2'),
        url('../../../assets/fonts/Ubuntu-Light.svg'),
        url('../../../assets/fonts/Ubuntu-Light.eot');
}

@font-face {
    font-family: 'Ubuntu Bold'; //This is what we are going to call
    src        : url('../../../assets/fonts/Ubuntu-Bold.ttf'),
        url('../../../assets/fonts/Ubuntu-Bold.woff'),
        url('../../../assets/fonts/Ubuntu-Bold.woff2'),
        url('../../../assets/fonts/Ubuntu-Bold.svg'),
        url('../../../assets/fonts/Ubuntu-Bold.eot'),
}

.font-regular {
    font-family: 'Ubuntu Regular';
}

.font-medium {
    font-family: 'Ubuntu Medium';
}

.font-bold {
    font-family: 'Ubuntu Bold';
}

.font-light {
    font-family: 'Ubuntu Light';
}

.size-8 {
    font-size: 0.5rem;
}


.pageContent {
    h1 {
        color      : $vBlue;
        font-size  : 1.125rem;
        line-height: 1.575rem;
        font-family: "Ubuntu Medium";
        margin     : 0 0 20px 0;
    }

    h2 {
        color      : $vBlue;
        font-size  : 1.125rem;
        line-height: 1.575rem;
        font-family: "Ubuntu Medium";
        margin     : 0 0 15px 0;
        padding-top: 10px;
    }

    p {
        color         : #505050;
        font-size     : 0.875rem;
        line-height   : 1.138rem;
        font-weight   : 400;
        padding-bottom: 20px;
        margin        : 0;
    }

    ul {
        margin-bottom: 20px;
        li {
            color      : #505050;
            font-size  : 0.875rem;
            line-height: 1.138rem;
            font-weight: 400;
            margin: 0 0 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    ol {
        margin-bottom: 20px;
        &.type-alpha{
            list-style: lower-alpha;
        }
        li {
            color      : #505050;
            font-size  : 0.875rem;
            line-height: 1.138rem;
            font-weight: 400;
            margin: 0 0 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}