@import "../../../theme/styles/base/ovante_variables.scss";

.ion-item {
  --padding-start: 0px;
  --inner-border-width: 0;
  --inner-padding-end: 5px;
  ion-radio{
    width: 32px;
    height: 32px;
    margin: 0;
    margin-right: 14px;
    --color: #B6B2AF;
    &.ios{
      border: 2px solid #B6B2AF;
      border-radius: 50%;
    }
    &.radio-checked {
      --color-checked: #2293B5;
      --color: #2293B5;
      position: relative;
      visibility: hidden;
      &::before {
        content: " ";
        display: block;
        position: absolute;
        background: url("../../../assets/images/icons/radio-active.svg") no-repeat;
        width: 32px;
        height: 32px;
        right: 0px;
        bottom: 0px;
        visibility: visible;
      }
      &.ios{
        &::before {
          right: -2px;
        }
      }
    }
  }
  .ion-label {
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.8rem;
    font-weight: 400;
    color: $vLightBlack;
  }
}
