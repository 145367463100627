@import "../../theme/styles/base/ovante_variables.scss";

.page-content-network-error{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ion-popup__success-icon{
  width: 47.66px;
  height: 42.52px;
}

.network-error__title{
  font-size: 1.375rem;
  line-height: 1.938rem;
  padding: 0 30px;
}
.network-error__detail-text{
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0 30px;
}
.network-error-btn{
  width: 74px;
  height: 39px;
}