@import "../../theme/styles/base/ovante_variables.scss";

.page-submit-story{
  .register-header {
    //background-color: $vDeepSkyBlue;
    background-color: transparent;
    background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
  }
  .page-content {
    --padding-top: 0px;
  }
}
.story-success__container, .submit-story__container{
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.story-success__text{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
  padding: 0 20px;
}
.story-success__icon{
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.story-success__title{
  font-size: 1.375rem;
  line-height: 1.925rem;
}
.story-success__submit, .story-success__description{
  font-size: 0.875rem;
  line-height: 1.225rem;
}
.story-success__btn, .submit-story__btn-container{
  margin-top: auto;
}
.story-people__icon{
  width: 40px;
  height: 50px;
  margin: 0 auto;
}
.success__description-orange{
  text-decoration: underline;
}
.submit-story__btn-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.story-upload__btn, .story-record__btn{
  width: 154px;
  height: 44px;
}