@import "../../theme/styles/base/ovante_variables.scss";

.page-install-app{
    @media screen and (min-width: 560px){
        max-width: 560px;
        margin: 0 auto;
    }
    .page-content{
        --padding-top: 100px;
    }
}

.page-install-app__bottom-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 25px 0 30px;
    left: 20px;
    &:after {
        content: "";
        background: url(../../assets/images/header-arch.png) no-repeat;
        position: absolute;
        top: -15px;
        z-index: 999;
        display: block;
        width: 100%;
        height: 17px;
        left: 0;
        background-size: contain;
        @media screen and (min-width: 560px){
            //background-size: cover;
            height: 20%;
            top: -22px;
        }
    }
}

.page-install-app__container{
    text-align: center;
    padding: 0 20px;
    @media screen and (min-width: 560px){
        position: relative;
        top: 30%;
        transform: translateY(-30%);
    }
}
.install-app-title{
    font-size: 1.563rem;
    line-height: 1.813rem;
}
.install-app__user, .install-app__desc{
    font-size: 1.125rem;
    line-height: 1.313rem;
}
.install-app-img__container{
    .install-app-img{
        width: 60px;
        height: 60px;
        margin: 0 auto;
    }
}
.install-app__download-text, .install-app__already-text{
    font-size: 1rem;
    line-height: 1.4rem;
}