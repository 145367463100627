:root {
    --ion-color-blue: #2293B5;
    --ion-color-blue-rgb: 34,147,181;
    --ion-color-blue-contrast: #ffffff;
    --ion-color-blue-contrast-rgb: 255,255,255;
    --ion-color-blue-shade: #1e819f;
    --ion-color-blue-tint: #389ebc;
}
.ion-color-blue {
    --ion-color-base: var(--ion-color-blue) !important;
    --ion-color-base-rgb: var(--ion-color-blue-rgb) !important;
    --ion-color-contrast: var(--ion-color-blue-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-blue-shade) !important;
    --ion-color-tint: var(--ion-color-blue-tint) !important;
}

$white: #ffffff;
$black: #000000;
$vLightBlack: #38322E;
$vDeepBlack: #003747;
$vpitchblack: #061D28;
$vspiderBlack: #010101;
$vBlue: #2293B5;
$vLightBlue: #26A5CA;
$vOrange:#FE6800;
$vGreen: #165F74;
$vGray: #505050;
$vLightGray: #9D9D9D;
$vGraySlate: #928B87;
$vPearlGray: #9B9896;
$vLightOrnage: #FDF6F1;
$vSkyBlue: #009FE8;
$vDeepSkyBlue: #73CBE5;
$vMaroon: #E50027;
$vLighMaroon: #E80000;

.text-white {
    color: $white;
}
.text-black {
    color: $black;
}
.text-deep-black {
    color: $vDeepBlack;
}
.text-light-black {
    color: $vLightBlack;
}
.text-pitch-black {
    color: $vpitchblack;
}
.text-spider-black {
    color: $vspiderBlack;
}
.text-blue {
    color: $vBlue;
}
.text-orange {
    color: $vOrange;
}
.text-green {
   color: $vGreen;
}
.text-gray {
    color: $vGray;
}
.text-light-blue {
    color: $vLightBlue;
}
.text-light-gray {
    color: $vLightGray;
}
.text-gray-slate {
    color: $vGraySlate;
}
.text-pearl-gray{
    color: $vPearlGray;
}
.text-maroon{
    color: $vMaroon;
}
.text-light-maroon{
    color: $vLighMaroon;
}


.bg-white {
    background-color: $white !important;
}
.bg-black {
    background-color: $black !important;
}
.bg-blue {
    background-color: $vBlue !important; 
}
.bg-orange {
    background-color: $vOrange !important;
}
.bg-green {
    background-color: $vGreen !important;
}
.bg-light-blue {
    background-color: $vLightBlue !important;
}
.bg-light-gray {
    background-color: $vLightGray !important;
}
.bg-light-orange {
    background-color: $vLightOrnage !important;
}
.bg-light-black {
    background-color: $vLightBlack !important;
}
.bg-sky-blue {
    background-color: $vSkyBlue !important;
}