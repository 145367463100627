@import '../../../theme/styles/base/ovante_variables.scss';

.empty-comment__container{
    text-align: center;
    padding: 0 55px;
}
.empty-comment__icon{
    width: 40px;
    height: 40px;
    margin: 0 auto;
}
.empty-comment__title{
    font-size: 1.125rem;
    line-height: 1.575rem;
}
.empty-comment__desc{
    font-size: 0.875rem;
    line-height: 1.138rem;
}