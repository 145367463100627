@import "../../theme/styles/base/ovante_variables.scss";

.page-diagnostic-question {
  .page-content {
    --padding-top: 0px;
    --padding-bottom: 0;
  }
  .stretch-container {
    background: $white;
  }
  .ion-item__label.ion-item__form-business {
    display: block;
    margin: 20px 0 10px;
  }
}
.diagnostic-question__header.stretch-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 30px;
  position: fixed;
  width: 100%;
  height: 230px;
  background-color: #1371b9;
  //z-index: 999;
  // background: url(../../assets/images/o-device.png) no-repeat #1371b9;
  // background-size: 14%;
  // background-position: 100% 145%;
  background: radial-gradient(105% 30% at bottom, transparent 50%, #1371B9 52%);
  @media screen and (min-width: 560px) {
    background: radial-gradient(105% 45% at bottom, transparent 50%, #1371B9 52%);
  }
  &:after {
    content: "";
    background: url(../../assets/images/o-device.png) no-repeat;
    width: 62px;
    height: 123px;
    position: absolute;
    z-index: 999;
    display: block;
    right: 0px;
    bottom: 25px;
    @media screen and (min-width: 560px) {
      bottom: 35px;
    }
  }
  .header-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $white;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: 500;
    margin: 0;
    margin-top: 10px;
    @supports (-webkit-touch-callout: none) {
      margin-top: 0px;
    }
  }
  @supports (-webkit-touch-callout: none) {
      padding-top: 50px;
  }
}
.page-content__range {
  background: $white;
  --bar-background-active: #fe6800;
  --bar-background: #e2e2e2;
  padding: 12px 10px;
  border-radius: 8px;
  --knob-size: 0px;
  --knob-border-radius: 0;
  --height: 4px;
  --knob-background: #fe6800;
  --bar-height: 4px;
  --bar-border-radius: 4px;
  pointer-events: none;
}
.diagnostic-questions__container{
  padding-bottom: 90px;
  position: relative;
  top: 240px;
  z-index: 1000 !important;
}
.diagnostic-questions__btn{
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  height: auto;
  z-index: 999;
  &::before{
      content: ' ';
      display: block;
      position: absolute;
      width: 100%;
      height: 15px;
      background: url("../../assets/images/add-task-bg.svg") no-repeat;
      top: -10px;
      left: 0px;
      background-size: 100%;
  }
}
.questions-status{
  padding: 10px 20px;
  border: 1px solid $vGreen;
  box-shadow: 0px 4px 16px rgba(47, 138, 165, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.questions-status-text{
  font-size: 0.875rem;
  line-height: 1.4rem;
}
.questions-status-img{
  width: 24px;
  height: 24px;
  display: table;
}