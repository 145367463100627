@import '../../../theme/styles/base/ovante_variables.scss';

.page-rewards{
    --ion-background-color: #ffffff;
    &.page-rewards-bg-light-orange{
        --ion-background-color: #FDF6F1;
    }
    .page-content__title {
        margin-bottom: 0;
    }
    .register-header__text{
        width: 100%;
    }
    .page-rewards__register-header {
        //background-color: #73CBE5;
        background-color: transparent;
        background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
        //margin-bottom: -20px;
        position: fixed;
        //width: calc(100% + 40px);
        .register-header__content{
            @supports (-webkit-touch-callout: none) {
                top: 45%;
             }
        }
    }
    .page-content__available-coins{
        position: fixed;
        top: 53px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
        @supports (-webkit-touch-callout: none) {
            top: 70px;
        }
    }
}

.rewards-available{
    font-size: 1.125rem;
    line-height: 1.575rem;
}
.rewards-available__description{
    font-size: 0.875rem;
    line-height: 1.138rem;
}
.page-rewards-container{
    background: $vLightOrnage;
    padding: 40px 20px 25px !important;
    @supports (-webkit-touch-callout: none) {
        padding-top: 60px !important;
    }
}
.page-rewards-lock-container{
    padding: 25px 20px 50px !important;
}
.lock-reward{
    font-size: 0.875rem;
    line-height: 1rem;
}
.lock-rewards__description{
    font-size: 0.75rem;
    line-height: 0.975rem;
}

.view-reward{
    .reward-img{
        width: 26px;
        height: 26px;
    }
}