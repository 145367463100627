@import '../../../theme/styles/base/ovante_variables.scss';

.ion-popup{
    ion-backdrop{
        --backdrop-opacity: 0.56;
    }
}
.popover-content{
    --background: #ffffff;
    display: flex;
    --width: 90%;
    left: 5% !important;
    padding: 50px 25px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-radius: 8px;
    &.sc-ion-popover-ios {
        top: 50% !important;
        transform: translate(0px, -50%);
    }
    @media screen and (min-width: 560px) {
        --width: 25%;
        left: 37.5% !important;
    } 
}
.ion-popup__success-icon{
    width: 50px;
    height: 50px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
}
.ion-popup__success-text{
    color: #003747;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.925rem;
    margin-bottom: 15px;
}
.ion-popup__text{
    color: $vLightBlack;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.225rem;
    margin-bottom: 30px;
}