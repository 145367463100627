@import '../../theme/styles/base/ovante_variables.scss';

.page-change-number{
    &.pos-btn{
        .page-content__button{
            position: relative;
            padding: 20px 0;
            width: 100%;
        }
    }
}
