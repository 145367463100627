@import '../../theme/styles/base/ovante_variables.scss';

.page-profile {
    --ion-background-color: #ffffff;
    .page-content__title {
        margin-bottom: 0;
    }
}
.page-profile__header{
    position: relative;
    .register-header {
        height: 230px;
        //background-color: #73CBE5;
        background-color: transparent;
        background: radial-gradient(130% 40% at bottom, transparent 50%, $vDeepSkyBlue 52%);
        margin-bottom: -30px;           ;
        .register-header__withbutton{
            top: 28px;
        }
        @supports (-webkit-touch-callout: none) {
            height: 270px;
            .register-header__withbutton{
                top: 55px;
            }
        }
    }
    .ion-button__setting-btn{
        width: 4px;
        height: 14px;
        background-position: center !important;
        display: block;
        position: absolute;
        top: calc(16% - 12px);
        right: 24px;
        z-index: 1000;
        height: 14px;
    }
    .profile__details{
        position: absolute;
        z-index: 1000;
        top: 16%;
        width: 100%;
        @media screen and (min-width: 560px) {
            max-width: 560px;
            left: 50%;
            transform: translateX(-50%);
        }
        @supports (-webkit-touch-callout: none) {
            top: 27%;
        }
    }
    &.hide-page-details{
        .register-header {
            height: 80px;
            transition: height 0.5s;
            background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
            .register-header__withbutton{
                position: fixed;
                top: 28px;
            }
            @supports (-webkit-touch-callout: none) {
                height: 120px;
                .register-header__withbutton{
                    top: 55px;
                }
            }
        }
        .profile__details{        
           opacity: 0;
           transition: opacity 0.5s;
           z-index: 998;
        }
        .ion-button__setting-btn{
            position: fixed;
            top: calc(5% - 12px);
        }
    }
}
.page-content-top-container{
    padding: 20px !important;
}    
.page-profile__all{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ion-button__all-icon{
    margin-left: 5px;
}
.ion-button__all{
    height: 25px;
    text-transform: capitalize;
}
.page-profile__instructions{
    font-size: 0.875rem;
    line-height: 1.138rem;
    padding-top: 5px;
}
.page-profile__badge-list{
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    &.badge-list-less-than-three{
        justify-content: flex-start;
        .badge-list{
            width: 33%;
            text-align: center;
            // &:nth-child(1) {
            //     .profile__badge-image{
            //         margin-left: 0;
            //     }
            // }
            // &:nth-child(2) {
            //     text-align: center;
            //     .profile__badge-image{
            //         margin: 0 auto;

            //     }
            // }
            // &:nth-child(3) {
            //     text-align: right;
            //     .profile__badge-image{
            //         margin-right: 0;
            //     }
            // }
        }
    }
    .mb-top{
        margin-top: -16px;
    }
    .badge-list{
        width: auto;
        text-align: center;
    }
    .profile__badge-image{
        width: 60px;
        height: 60px;
        display: block;
        margin: 0 auto;
        &.badge-image-large{
            width: 76px;
        }
    }
    .profile__badge-label{
        font-size: 0.625rem;
        line-height: 0.813rem;
        font-family: 'Ubuntu Medium';
    }
    
    .profile__badge-more{
        width: 38px;
        height: 38px;
        border-radius: 100%;
        background: rgba(34, 147, 181, 0.1);
        color: $vBlue;
    }
}
.page-profile__activities{
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
    .page-content__activity{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0px;
            .activity__image{
                &::after{
                    display: none;
                }
            }
        }
    }
    .activity__image{
        align-self: center;
        position: relative;
        &::after{
            content: ' ';
            display: block;
            position: absolute;
            width: 1px;
            height: 50px;
            //background: url('../../assets/images/dotted-lins.svg');
            left: 50%;
            transform: translateX(-50%);
            border-width: 0px 1px 0px 0px;
            border-style: dashed;
            border-color: #AEAEAE;
        }
        &::part(image){
            width: 24px;
            height: 24px;
        }
    }
    .activity__details{
        //width: 70%;
        margin-left: 15px;
    }
    .activity__name, .activity__time{
        font-size: 0.625rem;
        font-family: 'Ubuntu Medium';
        line-height: 0.875rem;
        color: $vBlue;
    }
    .activity__inner-text{
        font-size: 0.875rem;
        font-family: 'Ubuntu Medium';
        line-height: 1.225rem;
        color: $vLightBlack;
        overflow: visible;
        width: 120%;
    }
    .activity__time{
        color: #686868;
        margin-left: auto;
        width: 80px;
        text-align: right;
    }
}

.page-content__certificates{
    padding-top: 18px;
    // .page-content__title{
    //     margin-bottom: 20px;
    // }
    .certificates__quick-links{
        .quick-links__title{
            font-size: 0.875rem;
            line-height: 1.006rem;
            font-family: 'Ubuntu Medium';
            margin-bottom: 14px;
        }
        .quick-links__list{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
        .quick-link{
            width: 80px;
        }
        .quick__link-image{
            width: 60px;
            height: 60px;
            background: #FFFFFF;
            box-shadow: 0px 4px 16px rgba(47, 138, 165, 0.2);
            border-radius: 8px;
            margin: 0 auto;
            margin-bottom: 10px;
            &.rewards {
                background: url('../../assets/images/quick-link-rewards.svg') no-repeat white;
                background-position: center;
            }
            &.stories {
                background: url('../../assets/images/quick-link-stories.svg') no-repeat white;
                background-position: center;
            }
            &.help-center {
                background: url('../../assets/images/quick-link-help-centre.svg') no-repeat white;
                background-position: center;
            }
            &.settings {
                background: url('../../assets/images/quick-link-settings.svg') no-repeat white;
                background-position: center;
            }
            &.to-do{
                background: url('../../assets/images/quick-link-to-do.svg') no-repeat white;
                background-position: center;
                background-size: 50%;
            }
        }
        .quick__link-label{
            font-size: 0.625rem;
            line-height: 0.875rem;
            font-family: 'Ubuntu Medium';
        }
    }
}
.certificate-popup{
    .popover-wrapper{
        --max-height: 100%;
    }
    .popover-content{
        background: transparent !important;
        top: 15px !important;
        height: 100%;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        --width: 100%;
        left: 0 !important;
        &.sc-ion-popover-ios {
            //top: unset !important;
            transform: unset !important;
        }
    }
}