@import "../../theme/styles/base/ovante_variables.scss";
.page-profile-setting-container {
  ion-radio {
    &.radio-checked {
      display: none;
    }
  }
}
.page-profile-setting {
  .register-header {
    //background-color: #73CBE5;
    background-color: transparent;
    background: radial-gradient(
      105% 45% at bottom,
      transparent 50%,
      $vDeepSkyBlue 52%
    );
  }
  .ion-item {
    --inner-border-width: 0;
    --min-height: 0px;
    --inner-padding-end: 0;
  }
  .ion-margin {
    --ion-margin: 18px;
  }
  .page-content {
    --padding-end: 10px;
  }
}
.page-profile-setting__title {
  font-size: 0.875rem;
  line-height: 1rem;
  font-family: "Ubuntu bold";
}
.setting__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .setting__name {
    font-size: 1rem;
    line-height: 1.3rem;
    //font-family: 'Ubuntu Medium';
  }
  .setting__icon {
    width: 33px;
    height: 16px;
  }
}
ion-toggle {
  width: 33px;
  height: 18px;
  --handle-width: calc(16px - (3px * 2));
  --handle-height: calc(16px - (3px * 2));
  --handle-spacing: 4px;
  --handle-background-checked: #ffffff;
  --background-checked: #fe6800;
  .toggle-inner {
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
}
.delete-pop-up {
  --height: 250px;
  --width: 400px;
  @media screen and (max-width: 560px) {
    --width: 80%;
  }
}
.delete-account-container {
  padding: 20px;
}
.delete-account-button-wrapper {
  display: flex;
}
