@import "../../theme/styles/base/ovante_variables.scss";

.page-leaderboard {

  .register-header {
    margin-bottom: 0;
    background: none;
    height: 62px;
    .register-header__withbutton {
      top: 50%;
    }
    @supports (-webkit-touch-callout: none) {
      height: 102px;
      .register-header__withbutton{
          top: 65%;
      }
  }
  }
  .page-content {
    background: transparent;
    --background: transparent;
    --padding-top: 0px;
      &:before {
        content: "";
        background: url(../../assets/images/header-arch.png) no-repeat;
        position: absolute;
        top: -15px;
        z-index: 999;
        display: block;
        width: 100%;
        height: 17px;
        background-size: contain;
        background-position: top;
        animation-name: leaderboard-bg-animation;
        animation-duration: 0.5s;  
        animation-delay: 0.5s;
        animation-fill-mode: both;
        animation-timing-function: linear;
    }
  }
  .page-content__title {
    margin-bottom: 5px;
  }
  &.large-header {
    text-align: left;
  }
}

.abstract-img-light-blue {
  background: url(../../assets/images/reward-bg-blue.png) no-repeat;
  background-color: #73cbe5;
  background-size: cover;
}

.large-header--gradient-blue-leaderboard {
  background: rgb(115, 203, 229);
  background: linear-gradient(
    180deg,
    rgba(115, 203, 229, 1) 0%,
    rgba(115, 203, 229, 1) 66%,
    rgba(115, 203, 229, 1) 66%,
    rgba(115, 203, 229, 1) 100%
  );
}

.large-header--gradient-leaderboard {
  height: 100%;
  .large-header {
    background-position: center;
    background-color: transparent;
  }
}

.page-leaderboard-period {
  max-width: 160px;
  padding: 2px 20px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
  border-radius: 8px;
  margin: 0 auto;
  font-size: 1.125rem;
  line-height: 1.575rem;
}

.leaderboard-top-three-container {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  position: relative;
  top: 40px;
}
.leaderboard-profile-two,
.leaderboard-profile-three {
  top: 50px;
  height: 109.5px;
}
.leaderboard-profile__container {
  &:first-child {
    order: 2;
    animation-name: rank-one;
    animation-duration: 0.25s;  
    animation-delay: 1.5s;
    animation-fill-mode: both;
    animation-timing-function: linear;
  }
  &:nth-child(2) {
    order: 1;
    background: linear-gradient(
      180deg,
      #ffffff 16.74%,
      rgba(255, 255, 255, 0) 100%
    );
    top: 50px;
    height: 109.5px;
    animation-name: rank-other;
    animation-duration: 0.25s;  
    animation-delay: 2s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    .leaderboard-number {
      background: #cacaca;
    }
  }
  &:nth-child(3) {
    order: 3;
    background: linear-gradient(
      180deg,
      #662c33 16.74%,
      rgba(255, 255, 255, 0) 100%
    );
    top: 50px;
    height: 109.5px;
    animation-name: rank-other;
    animation-duration: 0.25s;  
    animation-delay: 2.5s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    .leaderboard-number {
      background: #662c33;
    }
  }
}
.leaderboard-cards {
  .leaderboard-card__container {
    &:first-child {
      .leaderboard-card__number {
        background: #fdc200;
        color: $white;
      }
      .leaderboard-card {
        border: 1px solid #fdc200;
      }
    }
    &:nth-child(2) {
      .leaderboard-card__number {
        background: #cacaca;
        color: $white;
      }
      .leaderboard-card {
        border: 1px solid #cacaca;
      }
    }
    &:nth-child(3) {
      .leaderboard-card__number {
        background: #662c33;
        color: $white;
      }
      .leaderboard-card {
        border: 1px solid #662c33;
      }
    }
  }
}

.leaderboard-card-view {
  padding: 0 20px 30px;
  animation-name: leaderboard-bg-animation;
  animation-duration: 0.5s;  
  animation-delay: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
}
.leaderboard-instuction {
  font-size: 0.875rem;
  line-height: 1rem;
  animation-name: leaderboard-content-animation;
  animation-duration: 0.75s;  
  animation-delay: 3s;
  animation-fill-mode: both;
  animation-timing-function: linear;
}
.leaderboard-cards{
  animation-name: leaderboard-content-animation;
  animation-duration: 0.75s;  
  animation-delay: 3s;
  animation-fill-mode: both;
  animation-timing-function: linear;
}
.leaderboard-bottom-box {
  padding: 25px 20px;
}
.leaderboard-footer-title {
  font-size: 0.875rem;
  line-height: 1rem;
}
.leaderboard-footer-desc {
  font-size: 0.75rem;
  line-height: 0.975rem;
}
.footer-desc-orange {
  text-decoration: underline;
}
.floatingView {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  bottom: 0px;
  position: absolute;
}
@keyframes leaderboard-bg-animation {
  from {
    transform: translateY(350px);
  }
  to {
    transform: translateY(0px);
  }
}
@keyframes rank-one {
  from {
    transform: translateY(200px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes rank-other {
  from {
    transform: translateY(200px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes leaderboard-content-animation {
  from {opacity: 0;}
  to {opacity: 1;}
}