@import '../../theme/styles/base/ovante_variables.scss';

.reward__img {
  margin: 0 auto;
}
.reward__title {
  font-family: "Ubuntu Bold";
  font-size: 1.125rem;
}

.reward__info {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.reward-content {
  color: $white;
  .reward__img {
    margin-bottom: 20px;
  }
  .reward__title {
  }

  .reward__info {
    --padding-top: 0;
    --padding-start: 0;
  }
}

.abstract-img {
  background: url(../../assets/images/reward-bg.png) no-repeat;
  background-size: 100%;
  background-color: $vGreen;
}

.abstract-img-blue {
  background: url(../../assets/images/reward-bg-blue.png) no-repeat;
  background-size: 100%;
  background-color: $vSkyBlue;
}

.abstract-img-green {
  background: url(../../assets/images/reward-bg-blue.png) no-repeat;
  background-size: 100%;
  background-color: #157642;
}

.abstract-img-purple {
  background: url(../../assets/images/reward-bg-blue.png) no-repeat;
  background-size: 100%;
  background-color: #6b43c0;
}

.bottom-box {
  margin-top: 99px;
  position: absolute;
  width: 100%;
  bottom: 0;
  &:after {
    content: "";
    background: url(../../assets/images/header-arch.png) no-repeat;
    position: absolute;
    top: -15px;
    z-index: 999;
    display: block;
    width: 100%;
    height: 17px;
    left: 0;
    right: 0;
    background-size: contain;
  }
  .bottom-box__title {
    color: $vBlue;
    font-family: "Ubuntu Medium", sans-serif;
    font-size: 1.125rem;
  }
  .capsul-card__extra-activities {
    margin-bottom: 44px;
  }
}

.bottom-box__noscroll {
    position: fixed;
    width: 100%;
    bottom: 0;
}

.large-header {
  text-align: center;
  .register-header {
    background: none;
  }
  .page-content,
  .reward-content {
    background: transparent;
    --background: transparent;
    color: $white;
  }
  &.abstract-img-green {
    .reward__img {
      max-width: 139px;
      width: 100%;
    }
  }
  &.abstract-img-blue {
    .reward__img {
      max-width: 190px;
      width: 100%;
    }
  }
}
