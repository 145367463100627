.ion-flex {
    display: flex;
}

.custom-button {
    background-color: $vOrange;
    padding: 10px 15px;
    color: $white;
    font-family: "Ubuntu Medium";
    font-size: 1rem;
    box-shadow: 0px 8px 16px rgba(241, 81, 13, 0.2);
    border-radius: 8px;
   
}

.custom-button__arrow {
  &:after {
      content: "";
    display: block;
    width: 11px;
    height: 13px;
    background: url('../../../assets/images/icons/right-arrow.svg') no-repeat;
    position: absolute;
    right: 10px;
    top: 12px;
  }

}