@import '../../../theme/styles/base/ovante_variables.scss';

.ion-button__btnfill{
  --background: #FE6800;
  --border-width: 0px;
  --color: #fff;
  --border-radius: 8px;
  margin: 0;
  font-size: 1rem;
  line-height: 1.149rem;
  font-weight: 500;
  height: 44px;
  --box-shadow: 0px 8px 16px rgba(241, 81, 13, 0.2);
  text-transform: none;
 --ripple-color: #F1510D;
  &:focus{
      outline: 0;
      --background: #F1510D;
  }
  @supports (-webkit-touch-callout: none) {
    --color: #fff !important;
  }
}
.ion-button__btnoutline{
  --background: #ffffff;
  --border-width: 2px;
  --border-style: solid;
  --border-color: #2293B5;
  --color: #2293B5;
  --border-radius: 12px;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.006rem;
  font-weight: 500;
  height: 44px;
  text-transform: none;
  --box-shadow: 0px 8px 16px rgba(34, 147, 181, 0.2);
  --ripple-color: #2293B5;
  &:focus{
      outline: 0;
  }
}
.button-disabled{
  --background: #F3F2F2;
  --color: #B6B2AF;
  --box-shadow: none;
  --border-width: 0px;
}