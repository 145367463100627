@import "../../theme/styles/base/ovante_variables.scss";

.page-reward-congratulation {
}
.page-reward-video {
  padding: 0;
  margin-top: -20px;
  position: relative;
  .ion-button__back-btn {
    position: absolute;
    left: 4px;
    top: 28px;
    z-index: 1;
    width: 40px;
    height: 40px;
  }
}

.reward-congratulation__title {
  font-size: 1.563rem;
  line-height: 1.813rem;
}
.reward-congratulation__redeemed {
  font-size: 1.125rem;
  line-height: 1.313rem;
}
.reward-congratulation__img {
  width: 122px;
  height: 122px;
  border-radius: 8px;
  margin: 0 auto;
}
.reward-congratulation__decription {
  font-size: 1.25rem;
  line-height: 1.438rem;
}
.reward-congratulation__coins {
  display: flex;
  align-items: center;
  justify-content: center;
}
.congratulation-coins__text {
  font-size: 0.875rem;
  line-height: 1.138rem;
  margin-left: 7px;
}
.reward-discount__code {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  line-height: 1.575rem;
  padding: 10px;
  border-radius: 8px;
  max-width: 279px;
  min-height: 55px;
  margin: 0 auto;
  .reward-discount__img {
    width: 279px;
    height: 55px;
  }
}
.reward-congratulation__instructions {
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.138rem;
  ul {
    padding-inline-start: 15px;
    margin-block-start: 0;
  }
}
.page-reward__btn-container {
  position: fixed;
  bottom: 0;
  width: calc(100% + 10px);
  padding: 12px 0 27px;
  left: 10px;
  &:after {
    content: "";
    background: url(../../assets/images/header-arch.png) no-repeat;
    position: absolute;
    top: -17px;
    z-index: 999;
    display: block;
    width: 100%;
    height: 17px;
    left: 0;
    background-size: cover;
    background-position: center;
  }
}
.page-reward__btn {
  width: 73px;
  height: 44px;
  margin: 0 auto;
}
.abstract-img-light-blue {
  background: url(../../assets/images/reward-bg-blue.png) no-repeat;
  background-color: #73cbe5;
  background-size: cover;
}
