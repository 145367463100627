@import '../../theme/styles/base/ovante_variables.scss';

.page-recorder-video { 
    --ion-background-color: #000000;
    .ion-button__back-btn{
        position: absolute;
        top: 20px;
        left: 20px;
        display: block;
        margin: 0 auto;
        z-index: 1;
    }   
}
.recorder-video__container {
    padding: 0;
    height: 100%;
}
.recorder-video__play{
    width: 64px;
    height: 70px;
}
.recorder-video__btn-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 12px 0 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
        content: "";
        background: url(../../assets/images/header-arch.png) no-repeat;
        position: absolute;
        top: -15px;
        z-index: 999;
        display: block;
        width: 100%;
        height: 17px;
        left: 0;
        background-size: contain;
    }
}
.redo__btn{
    width: 73px;
    height: 43px;
    letter-spacing: 0.01em;
}
.post-video__btn{
    width: 187px;
    height: 43px;
    letter-spacing: 0.01em;
}
